import React from 'react'

const Footer = () => (
  <>
    <hr />

    <h2>We're just getting started</h2>

    <p className="text-center">
      Join the <strong>#coronacamping</strong> movement to stay up-to-date.
    </p>

    <div className="mt-10 mb-3 text-center">
      <a href="https://twitter.com/coronacamping?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-size="large" data-show-screen-name="false" data-show-count="false">Follow @coronacamping</a>
    </div>

    <div className="mb-3 text-center">
      <div className="fb-like" data-href="https://www.facebook.com/coronacamping" data-width="" data-layout="button" data-action="like" data-size="large" data-share="true"></div>
    </div>

    <form className="text-center validate" action="https://coronacamp.us19.list-manage.com/subscribe/post?u=d764bf25e9f4e111b0279aa24&amp;id=aabeeeb4a8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div style={{position: 'absolute', left: -5000}} aria-hidden="true"><input type="text" name="b_d764bf25e9f4e111b0279aa24_aabeeeb4a8" tabIndex="-1" defaultValue="" /></div>
      <input className="w-32 mr-2 px-2 py-1 inline-block bg-white border rounded font-semibold text-sm" style={{borderColor: '#BBA984'}} type="email" name="EMAIL" placeholder="Your email" /><input className="px-3 py-1 bg-black font-medium rounded cursor-pointer hover:opacity-75 text-white text-sm font-semibold" type="submit" value="Sign up" />
    </form>

    <hr />

    <h2>Talk to a human</h2>
    <p className="text-center">
      Need help getting your Corona Camp up and running? Dealing with a roommate issue? Reach out!
    </p>
    <p className="text-center leading-9">
      <span className="uppercase font-bold text-sm tracking-wider text-yellow-700">Email</span> <a href="mailto:counselors@coronacamp.org">counselors@coronacamp.org</a>
    </p>
    <p className="text-center">
      We're here to help <span className="text-sm" role="img" aria-label="Heart">&#x2764;&#xFE0F;</span>
    </p>
  </>
)

export default Footer
