import React from "react"
import PropTypes from "prop-types"
import SEO from "./seo"
import Footer from "./footer"

const Container = ({ title, description, lang, meta, children }) => {
  return (
    <>
      <div id="fb-root"></div>
      <div className="mx-12 my-16 px-1">
        <div className="max-w-lg mx-auto">
          <SEO title={title} description={description} lang={lang} meta={meta} />
          {children}
          <Footer />
        </div>
      </div>
    </>
  )
}

Container.defaultProps = {
  title: '',
  description: '',
  lang: 'en',
  meta: [],
}

Container.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Container
