import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const SEO = ({ description, lang, meta, title }) => {
  const defaultTitle = 'Corona Camp'
  const defaultDescription = 'Staying sane while coliving in a quarantined world'
  const defaultAuthor = 'Corona Camp'

  const metaDescription = description || defaultDescription
  const fullTitle = title ? `${title} | ${defaultTitle}` : defaultTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={fullTitle}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: description,
        },
        {
          name: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: defaultAuthor,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v6.0&appId=629668417828666&autoLogAppEvents=1"></script>
      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-160919940-1"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-160919940-1');
        `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  title: '',
  description: '',
  lang: 'en',
  meta: [],
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
